import React, { useEffect, useState } from "react";
import "./css/DataCleanUp.css";
import LoadingSpinner from './LoadingSpiner';
import CalculatePoints from "./CalculatePoints";
import NewCSVPopup from "./NewCSVPopup";


function USSADataCleanup(props) {
    const csvData = props.csvData;
    const setCsvData = props.setCsvData;
    const headerInfo = props.headerInfo;
    const setHeaderInfo = props.setHeaderInfo;


    // STATES
    const [isLoading, setIsLoading] = useState(false);
    // popup for showing the changes that will be made to the csv data from the fis DB
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const  originalData = headerInfo.originalData;

    // FUNCTIONS
    const handlePopupClose = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const changeCSVDataForFIS = (data, newCSVData) => {
        const checkNames = (apiRow, row) => {
            // if they are the same then set the first name and last name to the values from the api
            if(row["firstname"] !== apiRow["first_name"]){
                row["firstname"] = apiRow["first_name"]
            };

            if(row["lastname"] !== apiRow["last_name"]){
                row["lastname"] = apiRow["last_name"]
            };
        }

        const checkFISPoints = (apiRow, row) => {
            // if they are the same then set the FIS Points to the values from the api
            if(row["FISpoints"] !== apiRow["fis_points"][headerInfo["discipline"]]){
                row["FISpoints"] = apiRow["fis_points"][headerInfo["discipline"]]
            };
        }

        const addFISPoints = (apiRow, row) => {
            // if they are the same then set the FIS Points to the values from the api
            let points = null
            try{
                points = parseFloat(apiRow["fis_points"][headerInfo["discipline"]])
            } catch(err) {
                points = null
            }
            row["FISpoints"] = points;
            return 1;
        }

        const checkNationality = (apiRow, row) => {
            if(row["Country"] !== apiRow['nation']){
                row["Country"] = apiRow["nation"]
                return 1;
            }
            return 0;
        }

        const checkYOB = (apiRow, row) => {
            if(row["YOB"] !== apiRow['date_of_birth'].split("-")[0]){
                row["YOB"] = apiRow['date_of_birth'].split("-")[0];
                return 1;
            }
            return 0;
        }

        const addGender = (apiRow, row) => {
            row['sex'] = apiRow['gender'];
        }

        var add_nation = 0;
        var add_YOB = 0;
        var add_fis_points = 0;

        newCSVData.forEach((row) => {
            if(row["FIScode"]){
                data.forEach((apiRow) => {
                    // check if the first name and last name are the same
                    if(row["FIScode"] === apiRow["fis_licence"]){
                        // check the names
                        checkNames(apiRow, row);
                        // add gender from fis data
                        addGender(apiRow, row);

                        // if there are fis points in the row then check them. else add them
                        if(row["FISpoints"]){
                            checkFISPoints(apiRow, row);
                        } else{
                            add_fis_points += addFISPoints(apiRow, row);
                        }
                        // check nationality of racer
                        add_nation += checkNationality(apiRow, row);
                        
                        // check YOB of racer
                        add_YOB += checkYOB(apiRow, row);
                    }
                });
            }
        });
        let headers = headerInfo.headers;
        if(add_nation > 0 && headers.includes('Country') === false){
            headers.push('Country');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_YOB > 0 && headers.includes('YOB') === false){
            headers.push('YOB');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_fis_points > 0 && headers.includes('FISpoints') === false){
            headers.push('FISpoints');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }

        return newCSVData;
    }

    const changeCSVDataForUSSA = (data, newCSVData) => {
        /* 
            Go through each racer and check if they have a FIS licence. 
            if they do then great. just change their USSA points. if they dont
            then go through the info and change their Fname, Lname Sex, Nation, 
            yearofbirth and ussa points
        */ 
        const addUSSAPoints = (apiRow, row) => {
            // if they are the same then set the FIS Points to the values from the api
            row["NRLpoints"] = parseFloat(apiRow["points"][headerInfo["discipline"]] || 990)
        }

        const checkRestOfRacerInfo = (apiRow, row) => {
            row['firstname'] = apiRow['first_name']
            row['lastname'] = apiRow['last_name']
            row['YOB'] = apiRow['date_of_birth']
            row['Country'] = 'USA'
        }

        newCSVData.forEach((row) => {
            if(row['FIScode']){
                data.forEach((apiRow) => {
                    // check if the first name and last name are the same
                    if(row["USSAcode"] === apiRow["USSAcode"]){
                        // check if the USSA points are correct
                        addUSSAPoints(apiRow, row);
                    }
                });
            }
            else if(row["USSAcode"]){
                data.forEach((apiRow) => {
                    // check if the first name and last name are the same
                    if(row["USSAcode"] === apiRow["USSAcode"]){
                        // check if the USSA points are correct
                        addUSSAPoints(apiRow, row);
                        checkRestOfRacerInfo(apiRow, row);
                    }
                });
            }
        });

        return newCSVData;
    }

    const checkForMissingUSSARacers = async (data, licenceList) => {
        const parseXMLString = (xmlString) => {
            let result = {};

            // Splitting XML string based on tags
            const tags = xmlString.split('<');

            for(let i = 0; i < tags.length; i ++){
                for( let j = i; j < tags.length; j++){
                    //  if its not a closing tag
                    if(!tags[j].split('>')[1]?.includes('\n')){
                        if(tags[j].includes('ussa_id>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['USSAcode'] = item[1]
                            }
                        }
                        if(tags[j].includes('last_name>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['lastname'] = item[1]
                            }
                        }
                        if(tags[j].includes('first_name>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['firstname'] = item[1]
                            }
                        }
                        if(tags[j].includes('gender>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['gender'] = tags[j].split('>')[1]
                            }
                        }
                        if(tags[j].includes('year_of_birth>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['year'] = tags[j].split('>')[1]
                            }
                        }
                        if(tags[j].includes('member_current>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['member_current'] = tags[j].split('>')[1]
                            }
                        }
                        if(result['USSAcode'] && result['lastname'] && result['firstname'] && result['gender'] && result['year']){
                            break
                        }
                    }
                }
            }

            return result || null
        }

        // check the new data for racers that got nouthing trutned for them. in that case then hit the api for them and add thei names, country and yob
        let USSAcodes = [];
        (data || []).forEach((racer) => {
            USSAcodes.push(racer['USSAcode'])
        })

        let notKnownRacers = []

        // hit the fis api for each fis list untill it returns one
        let apiUrl = 'https://bpw6fhnfw2qknsr3zgiavzh5ly0akhfd.lambda-url.us-west-2.on.aws/'

        try {
            // Send a GET request to the API endpoint using fetch
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    },
                body: JSON.stringify({ USSAcodes }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            // Parse the XML response
            const returnedUSSAData = await response.json()
            USSAcodes.forEach((USSAID) =>{
                let parsedData = parseXMLString(returnedUSSAData[USSAID])
                if(parsedData){
                    if(parsedData['member_current'] === 'no'){
                        data.forEach((racer) => {
                            if(racer['USSAcode'] === USSAID){
                                notKnownRacers.push(racer)
                            }
                        })
                    }
                    // this is the person to add
                    let personInfo = {}
                    personInfo['first_name'] = parsedData['firstname']
                    personInfo['last_name'] = parsedData['lastname']
                    personInfo['gender'] = parsedData['gender']
                    personInfo['nation'] = parsedData['nationcode']
                    // personInfo['ussa_id'] = parsedData['USSAcode']
                    personInfo['year_of_birth'] = parsedData['year']
                    // personInfo['fis_points'] = {'DI': null, 'SP': null}
                    data.push(personInfo)
                }
            })
            
        
        } catch (error) {
            console.error('Error fetching data for USSA racers from USSA information lambda', error.message);
        }

        return [data, notKnownRacers]
    }

    const checkForMissingFISRacers = async (data, licenceList) => {
        /**
         * go through the data (list of racers returned by points list) and check 
         * if its missing any from the licenceList. if it is then hit the FIS API 
         * for those racers to get information on them. then reurn the list of new 
         * data and the list of the unknown racers.
         */

        const parseXMLString = (xmlString) => {
            let result = {};

            // Splitting XML string based on tags
            const tags = xmlString.split('<');

            for(let i = 0; i < tags.length; i ++){
                for( let j = i; j < tags.length; j++){
                    //  if its not a closing tag
                    if(!tags[j].split('>')[1]?.includes('\n')){
                        if(tags[j].includes('fiscode>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['fiscode'] = item[1]
                            }
                        }
                        if(tags[j].includes('lastname>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['lastname'] = item[1]
                            }
                        }
                        if(tags[j].includes('firstname>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['firstname'] = item[1]
                            }
                        }
                        if(tags[j].includes('gender>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['gender'] = item[1]
                            }
                        }
                        if(tags[j].includes('nationcode>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['nationcode'] = item[1]
                            }
                        }
                        if(tags[j].includes('year>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['year'] = item[1]
                            }
                        }
                        if(tags[j].includes('status>')){
                            let item = tags[j].split('>')
                            if(!item[0].includes('/')){
                                result['status'] = item[1]
                            }
                        }
                        if(result['fiscode'] && result['lastname'] && result['firstname'] && result['gender'] && result['nationcode'] && result['year']){
                            break
                        }
                    }
                }
            }

            return result || null
        }

        // check the new data for racers that got nouthing trutned for them. in that case then hit the api for them and add thei names, country and yob
        let FIScodes = [];
        (data || []).forEach((racer) => {
            FIScodes.push(racer['fis_licence'])
        })

        console.log('getting information of FIS racers:', FIScodes)

        let notKnownRacers = []

        // hit the fis api for each fis list untill it returns one
        let apiUrl = 'https://p474fmngovil3jmfzxh75fuvg40ksjuf.lambda-url.us-west-2.on.aws/'

        try {
            // Send a GET request to the API endpoint using fetch
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ FIScodes }),
            });         

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        
            // Parse the XML response
            const returnedFISData = await response.json()
            FIScodes.forEach((FISID) => {
                if(!returnedFISData[FISID]){
                    console.log('NO FIS XML FOR:', FISID)
                    console.log('NO FIS XML FOR:', returnedFISData[FISID])
                }
                let parsedData = parseXMLString(returnedFISData[FISID])
                if(parsedData){
                    if(parsedData['status'] === 'E'){
                        data.forEach((racer) => {
                            if(racer['FIScode'] === FISID){
                                notKnownRacers.push(racer)
                            }
                        })
                    }
                    // this is the person to add
                    let personInfo = {}
                    personInfo['first_name'] = parsedData['firstname']
                    personInfo['last_name'] = parsedData['lastname']
                    personInfo['gender'] = parsedData['gender']
                    personInfo['nation'] = parsedData['nationcode']
                    // personInfo['fis_licence'] = parsedData['fiscode']
                    personInfo['date_of_birth'] = parsedData['year']
                    // personInfo['fis_points'] = {'DI': null, 'SP': null}
                    data.push(personInfo)
                }
            })

        
            } catch (error) {
                console.error('Error fetching data for FIS racers from FIS information lambda', error.message);
            }
        return [data, notKnownRacers]
    }

    const getFISData = () => {
        return new Promise((resolve, reject) => {
            var racers = [];
            var noFISlicence = [];
            (csvData).forEach((row) => {
                if(row["FIScode"]){
                    racers.push(row["FIScode"]);
                } else{
                    noFISlicence.push(row)
                }
            });

            var points_list = parseInt(headerInfo['usedFISList']);

            const data_to_send = new FormData();
            data_to_send.append('points_list', points_list);
            data_to_send.append('racers', racers);
            data_to_send.append('return_csv', false);

            fetch("https://ggpwjy2pju3cttzzmoxhnuidyi0bkuwd.lambda-url.us-west-2.on.aws", {
            method: 'POST',
            body: data_to_send
            })
            .then(response => response.json())
            .then(data => {
                if(data["message"] === undefined){
                    checkForMissingFISRacers(data, racers)
                    .then((result)=>{
                        // result[0] is the new data and result[1] is the list of unknown fis racers(with expired licences)
                        let newCSVData = changeCSVDataForFIS(result[0], csvData);
                        let unknownFISRacers = result[1]
                        resolve([newCSVData, unknownFISRacers, noFISlicence])
                    })
                    .catch((err)=>{
                        console.warn('Error:', err)
                    })
                }
                else{
                    window.alert(data["message"]);
                    setIsLoading(false);
                    reject(data['message'])
                }
            })
        })
    }

    const getUSSAData = (newCSVData) => {
        return new Promise((resolve, reject) => {
            var racers = [];
            var noUSSALicence = [];
            (newCSVData || csvData).forEach((row) => {
                if(row["USSAcode"]){
                    racers.push(row["USSAcode"]);
                } else{
                    noUSSALicence.push(row)
                }
            });

            console.log('USSA racers in getUSSAData:', racers)

            var points_list = parseInt(headerInfo['usedNRLList']);

            const data_to_send = new FormData();
            data_to_send.append('points_list', points_list);
            data_to_send.append('racers', racers);
            data_to_send.append('return_csv', false);
            data_to_send.append('date', headerInfo.raceDate.split("-"));

            fetch("https://2ibtetclowg2xpmh47qlpd7egy0tneqv.lambda-url.us-west-2.on.aws/", {
                method: 'POST',
                body: data_to_send
            })
            .then(response => response.json())
            .then(data => {
                if(data["message"] === undefined){
                    checkForMissingUSSARacers(data, racers)
                    .then((result)=>{
                        newCSVData = changeCSVDataForUSSA(result[0], newCSVData);
                        let notKnownUSSARacers = result[1]
                        resolve([newCSVData, notKnownUSSARacers, noUSSALicence])
                    })
                    .catch((err)=>{
                        console.warn('Error:', err)
                        setIsLoading(false)
                    })
                }
                else{
                    window.alert(data["message"]);
                    setIsLoading(false);
                    reject(data['message'])
                }
            })
            .catch(error => {
                console.log("Error. are you sure there are NRL licences?:", error);
                window.alert("Something seems to be wrong with your USSA Codes.")
                setIsLoading(false)
            });
        })
    }

    const reRankRacers = (data, racersToRemove) => {
        let FISCodesToRemove = racersToRemove.map((racer) => racer['FIScode'])
        let USSACodesToRemove = racersToRemove.map((racer) => racer['USSAcode'])
        let namesToRemove = racersToRemove.map((racer) => racer['firstname'] + racer['lastname'])

        // remove the racers from the results
        let updatedCsvData = []
        data.forEach((racer) => {
            if(racer['FIScode']){
                if(!(FISCodesToRemove.includes(racer['FIScode']) || USSACodesToRemove.includes(racer['USSAcode']))){
                    updatedCsvData.push(racer)
                }
            } else if(racer['USSAcode']){
                if(!(FISCodesToRemove.includes(racer['FIScode']) || USSACodesToRemove.includes(racer['USSAcode']))){
                    updatedCsvData.push(racer)
                }
            } else{
                // if no licence
                if(!(namesToRemove.includes(racer['firstname'] + racer['lastname']))){
                    updatedCsvData.push(racer)
                }
            }
        })

        console.log('updatedCSV:', updatedCsvData)

        // now update the ranking for the updatedCSVData based on the time. if theyhvae the same time then give them the same rank
        let updatedRank = 1
        let lastTime = 0
        let skipRankFromTie = 0
        updatedCsvData.forEach((racer, idx) => {
            if(racer['status'] === '' || racer['status'] === 'QLF'){
                if(racer["time"] !== lastTime){
                    racer["rank"] = updatedRank + skipRankFromTie
                    // skipRankFromTie = 0
                    updatedRank += 1
                }
                else{
                    racer["rank"] = updatedRank - 1 + skipRankFromTie
                    skipRankFromTie += 1
                }
                lastTime = racer["time"]
            }
        })

        return updatedCsvData
    }

    const cleanData = async () => {
        setIsLoading(true);
        console.log('csv data at the start:', csvData)
        // get FIS data
        let fisResult = await getFISData()
        let invalidFISRacers = fisResult[1]
        fisResult = fisResult[0]
        // get USSA data to add onto fis data
        let ussaResult = await getUSSAData(fisResult)
        let invalidUSSARacers = ussaResult[1]
        ussaResult = ussaResult[0]

        let invalidUSSACodes = invalidUSSARacers.map((racer) => racer['USSAcode'])
        let invalidFISCodes = invalidFISRacers.map((racer) => racer['FIScode'])
        
        let invaladLicences = []
        /**
         * go through each racer and check to see if 
         * they have a fis or USSA licence. if they do 
         * check to see if the FIS or USSA is valad. 
         * if borth invalad then remove them
         */
        csvData.forEach((racer) => {
            let valadUSSALicence = true
            let valadFISLicence = true
            if(racer['USSAcode']){
                if(invalidUSSACodes.includes(racer['USSAcode'])){
                    valadUSSALicence = false
                    if(racer['FIScode']){
                        if(invalidFISCodes.includes(racer['FIScode'])){
                            valadFISLicence = false
                        }
                    } else {
                        valadFISLicence = false
                    }
                }
            } else if(racer['FIScode'] && valadFISLicence && valadUSSALicence){
                if(invalidFISCodes.includes(racer['FIScode'])){
                    valadFISLicence = false
                    if(racer['USSAcode']){
                        if(invalidUSSACodes.includes(racer['USSAcode'])){
                            valadUSSALicence = false
                        }
                    } else {
                        valadUSSALicence = false
                    }
                }
            } else {
                valadFISLicence = false
                valadUSSALicence = false
            }
            if(!valadFISLicence && !valadUSSALicence){
                invaladLicences.push(racer)
            }
        })

        let racersToFlagRed = invaladLicences
        console.log('racers to flag red:', racersToFlagRed)

        //rerank the skiiers here and remove skiiers in the 
        ussaResult = reRankRacers(ussaResult, racersToFlagRed)

        console.log('new results:', ussaResult)

        setIsLoading(false);
        setPopupData(ussaResult);
        setIsPopupOpen(true);
    }

    // calculate the top 5 racers with the lowest fis points. this is hard coded and needs to change depending on the fild of the csv.
    const calculateTop5Racers = () => {
        const sortedPeople = [...csvData].sort(
            (a, b) => (a["FISpoints"] || a["USSApoints"]) - (b["FISpoints"] || [b['USSApoints']])
        );
        
        const filteredRacers = sortedPeople.filter(
            racer => racer.rank >= 1 && racer.rank <= 5
        );
        
        const sortedFilteredRacers = [...filteredRacers].sort(
            (a, b) => a.rank - b.rank
        );
        // set the header info wutht he top 5 racers
        setHeaderInfo((prevState) => ({
            ...prevState,
            "top5Racers":sortedFilteredRacers
            }));
    } 

    // make the popup visable or not
    const ToggleCalculatePenalty = () => {
        // create popup for calculating penalty
        const overlay = document.getElementById('overlay');
        if (overlay.style.display === 'flex') {
            overlay.style.display = 'none';
        } else {
            overlay.style.display = 'flex';
            calculateTop5Racers();
        }
    }

    useEffect(() => {
        console.log('original data changed:', originalData)
    }, [originalData])

  return (
    <>
      <div id="dataCleanUp">
        <div id="dataCleanUpBox">
          <div id="instr">
            Once you have imput all the correct info for the Header Section. you
            can then calculate the USSA points for the race if they do not have
            any. First you can check the data agenst the USSA/FIS database. To do so
            use the button below. then you can calculate the FIS points. 
          </div>
            <h4>
                NOTE: This may take a while depending on how many racers you have in the race file!
            </h4>
          <button id="checkData" onClick={cleanData} disabled={isLoading || !csvData || !(csvData.length > 0)}>
            {isLoading ? <LoadingSpinner /> : "Check Data against USSA Data Base"}
          </button>
          <button id="calcAndTog" onClick={ToggleCalculatePenalty} disabled={!csvData || !(csvData.length > 0) || headerInfo.format === 'sprintFinal'}>
            Calculate Penalty / Race Points
          </button>
          
        </div>

        <div id="calculate-points">
            <CalculatePoints 
                headerInfo={headerInfo} 
                setHeaderInfo={setHeaderInfo} 
                csvData={csvData} 
                setCsvData={setCsvData} 
                ToggleCalculatePenalty={ToggleCalculatePenalty}
                parentElement={'ussa'}
            />
        </div>
      </div>

      <NewCSVPopup
        isPopupOpen={isPopupOpen}
        closeWindow={handlePopupClose}
        newData={popupData}
        oldData={originalData}
        headerInfo={headerInfo}
        headers={headerInfo.headers}
        setCsvData={setCsvData}
      />

    </>
  );
}

export default USSADataCleanup