import React, { useState } from 'react';
import './css/Zone4Header.css'
import logo from '../logos/zone4.png'; 

function Zone4Header(props){
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const toggleDropdown = () => {
      setIsDropdownVisible(!isDropdownVisible);
    };

    return(
        <>
            <div className='zone4HeaderTitle'>
                {/* <h2 className="title">Zone 4</h2> */}
                <img src={logo} alt="Zone 4 Logo" className='zone4-logo'/>
                <div className='more' onClick={toggleDropdown}>{`App Version: ${props.version}`}</div>
            </div>

            {/* Dropdown content */}
            {isDropdownVisible && (
                <div className="dropdown-content">
                <span>{`App Version: ${props.version}`}</span>
                {/* <span>Download FIS CSV Points List</span> */}
                </div>
            )}
        </>
    )
}

export default Zone4Header