import React, { useState } from 'react';
import './USSAPointsDownload.css';
import LoadingSpiner from './util/LoadingSpiner';

function USSAPointsDownload(props) {
    const [distanceURL, setDistanceURL] = useState(null);
    const [sprintURL, setSprintURL] = useState(null);
    const [overallURL, setOverallURL] = useState(null);
    const [pointsList, setPointsList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { headerInfo } = props;


    const get_ussa_csv = () => {
        setIsLoading(true);
        // var points_list = 1;
        var racers = [];
        var need_csv = true;
        const data_to_send = new FormData();
        data_to_send.append('pointsList', pointsList || 1);
        data_to_send.append('racers', racers);
        data_to_send.append('return_csv', need_csv);
        data_to_send.append('date', headerInfo.raceDate.split("-"));

        fetch("https://2ibtetclowg2xpmh47qlpd7egy0tneqv.lambda-url.us-west-2.on.aws/", {
            method: 'POST',
            body: data_to_send
        })

        
        fetch("https://2ibtetclowg2xpmh47qlpd7egy0tneqv.lambda-url.us-west-2.on.aws/", {  //https://ggpwjy2pju3cttzzmoxhnuidyi0bkuwd.lambda-url.us-west-2.on.aws
          method: 'POST',
          body: data_to_send
        })
        .then(response => response.json())
        .then(data => {
          console.log("---data", data);
          // download the csv file 
          const sprint_url = data[0];
          const distance_url = data[1];
          const overall_url = data[2];

          setDistanceURL(distance_url);
          setSprintURL(sprint_url);
          setOverallURL(overall_url);
          setIsLoading(false);
          
        }
        )
      }
      
    return(
        <>
        <div id="USSA-points-download">
            <div className='instr'>This gives you a CSV file with points for all racers for the chosen USSA points list.</div>
            <div className='fourm'>
                <input type='text' id='USSA-points' className='points-input' onChange={(event) => setPointsList(event.target.value)}></input>
                <button className='centerd-button'  onClick={get_ussa_csv} disabled={isLoading}>
                    {isLoading ? <LoadingSpiner/> : 'Get USSA Lists'}
                </button>
            </div>
            <div className='download-links'>
                {distanceURL && (
                    <a href={distanceURL} download="sprint.csv">
                        Download Distance CSV
                    </a>
                )}
                {sprintURL && (
                    <a href={sprintURL} download="sprint.csv">
                        Download Sprint CSV
                    </a>
                )}
                {overallURL && (
                    <a href={overallURL} download="overall.csv">
                        Download Overall CSV
                    </a>
                )}
            </div>
        </div>
        </>
    )
}

export default USSAPointsDownload;